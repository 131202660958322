import React from "react"
import PropTypes from "prop-types"

/* Import Local Style(s) */
import "./page-article-footer.scss"

const PageArticleFooter = ({ children, className }) => (
  <footer className={`page__article__footer ${className ? className : ""}`}>
    {children}
  </footer>
)

PageArticleFooter.propTypes = {
  header: PropTypes.node,
  className: PropTypes.string,
}

export default PageArticleFooter
