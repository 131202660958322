import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import PropTypes from "prop-types"

/* Import Global Asset(s) */
import EllipseSvgSrc from "~assets/images/ellipse.svg"

/* Import Local Style(s) */
import "./page-article-link.scss"

const PageArticleLink = ({ to, copy, className = null, external = false }) => (
  (external) ?
  <a className={`page__article__link ${className ? className : ""}`} href={to} target="_blank">
    <span className="ellipse">
      <img src={EllipseSvgSrc} alt="" />
    </span>
    <span className="action">{copy}</span>
  </a>
  : <Link className={`page__article__link ${className ? className : ""}`} to={to}>
    <span className="ellipse">
      <img src={EllipseSvgSrc} alt="" />
    </span>
    <span className="action">{copy}</span>
  </Link>
)

PageArticleLink.propTypes = {
  copy: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default PageArticleLink
