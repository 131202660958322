import React from "react"
import PropTypes from "prop-types"
import slug from "slug"

/* Import Local Style(s) */
import "./page-article-header.scss"

const PageArticleHeader = ({ header, className = null }) => (
  <header
    className={`page__article__header page__article__header--${slug(header)} ${
      className ? className : ""
    }`}
  >
    <h2>{header}</h2>
  </header>
)

PageArticleHeader.propTypes = {
  header: PropTypes.string.isRequired,
  className: PropTypes.string,
}

export default PageArticleHeader
