import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const Image = ({ image, alt = "", objectFit = "contain"}) => (
  <GatsbyImage
    image={image}
    // backgroundColor="#ffffff"
    objectFit={objectFit}
    loading="eager"
    placeholder="none"
    layout="fullWidth"
    alt={alt}
  />
)

export default Image
