import React from "react"
import { Link } from "~components/linkWithPreviousUrl/linkWithPreviousUrl"
import classnames from "classnames"
import PropTypes from "prop-types"

/* Import Local Style(s) */
import "./card.scss"

const Card = ({
  children,
  backgroundColor = "transparent",
  className = null,
  to = null,
}) => {

  const internal = /^\/(?!\/)/.test(to)

  return to ? (
    (internal) ? (
      <Link
        className={classnames("card", className)}
        style={{ backgroundColor }}
        to={to}
      >
        {children}
      </Link>
      ) : (
      <a
        className={classnames("card", className)}
        style={{ backgroundColor }}
        target="_blank"
        href={to}
      >
        {children}
      </a>
      )
  ) : (
    <div className={classnames("card", className)} style={{ backgroundColor }}>
      {children}
    </div>
  )

  
}

Card.propTypes = {
  children: PropTypes.node,
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  to: PropTypes.string,
}

export default Card
