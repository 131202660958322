import React from "react"
import { parseISO } from "date-fns"
import { format } from "date-fns-tz"
import slug from "slug"

/* Import Global Component(s) */
import Card from "~components/card/card"
import Image from "~components/image/image"

/* Import Local Style(s) */
import "./edit-item-card.scss"

const EditItemCard = ({ date, title, byline, color, image, noTarget }) => { 

  
  const targetUrl = `/edits/post/${slug(title)}`

  const bg = (color && color.color) ? color.color.hex : 'var(--color-lightest-gray)'

  return title !== undefined ? (
  <Card
    className="edit-item col col--1_3 col--tile mcol--full"
    backgroundColor={bg}
    to={(noTarget) ? null : targetUrl}
    key={slug(title)}
  >
    <p 
      className="title" 
      style={{ backgroundColor: bg, '--border': bg }}
    >{title}</p>
    { image && <div className="edits_image_wrapper">
      <Image image={image.asset.gatsbyImageData} />
    </div> }

    <p className="date">
      {byline}
    </p>
  </Card>
): null }

export default EditItemCard
